import { render, staticRenderFns } from "./App.vue?vue&type=template&id=e49575e2&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_webpack@5.88.2__handlebars@4.7.8_lodash@4.17.21_pre_8c9d3393923e3d7217a7d02e52813e84/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports